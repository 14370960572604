<template>
  <div class="wrapper postcard-bar-comp">
    <div id="downloadWrapper" :class="pdfAttachments.length > 0 ? 'pdfDownload' : 'otherDownload'">
      <vue-html2pdf
        ref="html2Pdf"
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :manual-pagination="true"
        :filename="setPdfName()"
        :image="{ type: 'svg', quality: 1.5 }"
        :pdf-quality="1.5"
        :html-to-pdf-options="{ html2canvas: { logging: false } }"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
      >
        <section slot="pdf-content">
          <section slot="pdf-item" class="html2pdf__page-break">
            <v-app>
              <v-main>
                <v-container
                  style="margin: 20px; background-color: rgba(0, 0, 0, 0.05); width: 645px"
                >
                  <PostCard :postcard_info="postcard_mutable" class="align-center" />
                </v-container>
              </v-main>
            </v-app>
          </section>
          <section
            v-for="image in imageAttachments"
            slot="pdf-item"
            :key="image.name"
            class="html2pdf__page-break"
          >
            <v-app>
              <v-main>
                <v-container>
                  <div v-viewer class="images">
                    <img :src="image.src" width="600" alt="angehängtes Bild" />
                  </div>
                </v-container>
              </v-main>
            </v-app>
          </section>
          <section v-for="pdf in pdfAttachments" slot="pdf-item" :key="pdf.name">
            <v-app>
              <v-main>
                <v-container style="width: 750px">
                  <VuePDF
                    v-for="i in pdf.numPages"
                    :key="i"
                    :src="pdf.src"
                    :page="i"
                    :class="i !== pdf.numPages ? 'html2pdf__page-break' : ''"
                  />
                </v-container>
              </v-main>
            </v-app>
          </section>
        </section>
      </vue-html2pdf>
    </div>
    <div v-if="menuShown" class="contextMenu">
      <ContextMenu :id="id" :topic="topic" :sender="sender" />
    </div>
    <div class="bar">
      <Button
        :is-action="true"
        icon="download"
        class="button"
        @click.native="downloadPostcard($event)"
      />
      <Button :is-action="true" icon="share" class="button" @click.native="sharePostcard($event)" />
      <Button
        v-if="!postcard_mutable.isSaved"
        :is-action="true"
        icon="bookmark"
        @click.native="savePostcard($event)"
      />
      <Button
        v-if="postcard_mutable.isSaved"
        :is-action="true"
        icon="bookmarkFilled"
        @click.native="savePostcard($event)"
      />
    </div>
  </div>
</template>
<script>
import Button from "./Button";
import ContextMenu from "@/components/ContextMenu";
import Api, { baseURL } from "@/services/api";
import { getLoggedInUser } from "@/services/loggedIn";
import PostCard from "./PostCard";
import VuePDF from "vue-pdf";

export default {
  name: "PostcardBar",
  components: { ContextMenu, Button, PostCard, VuePDF },
  props: {
    id: {
      type: Number,
      required: false,
    },
    bookmarked: {
      type: Boolean,
      required: false,
      default: false,
    },
    topic: { type: String },
    sender: { type: String },
    postcard: { type: Object },
  },
  data() {
    return {
      menuShown: false,
      attachments: [],
      pdfAttachments: [],
      imageAttachments: [],
      postcard_mutable: this.postcard, // https://v2.vuejs.org/v2/guide/migration.html#Prop-Mutation-deprecated
    };
  },
  mounted() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute(
      "src",
      "https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.8.0/html2pdf.bundle.min.js"
    );
    document.head.appendChild(externalScript);

    this.attachments = this.postcard_mutable.attachments
      ? this.renderAttachments(this.postcard_mutable.attachments)
      : [];
    this.attachments.forEach((attachment) => {
      if (attachment.type == "pdf") {
        let task = VuePDF.createLoadingTask(attachment.src);
        let pdf = {
          name: attachment.name,
          src: task,
        };
        task.promise.then((pdfFile) => {
          pdf.numPages = pdfFile.numPages;
          this.pdfAttachments.push(pdf);
        });
      } else {
        this.imageAttachments.push(attachment);
      }
    });
  },
  methods: {
    downloadPostcard(event) {
      event.stopPropagation();
      this.$refs.html2Pdf.generatePdf();
    },
    setPdfName() {
      return "Postkarte zum Thema " + this.topic;
    },
    sharePostcard(event) {
      event.stopPropagation();
      this.menuShown = !this.menuShown;
      this.$emit("passData");
    },
    savePostcard(event) {
      event.stopPropagation();
      if (getLoggedInUser()) {
        //Remove next line once Api works!!!
        if (this.id) {
          Api.setPostSaved(this.id).then((res) => (this.postcard_mutable.isSaved = res.data));
        }
      } else {
        this.$emit("showModal");
      }
    },
    renderAttachments(attachments) {
      const att = [];
      attachments.forEach((attach) => {
        att.push({
          name: attach.name,
          type: attach.static_file_path.split(".")[1],
          src: baseURL + "public/" + attach.static_file_path,
        });
      });
      return att;
    },
  },
};
</script>
