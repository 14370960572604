<template>
  <div class="administration-comp text-left">
    <div style="float: left" >
      <v-navigation-drawer permanent>
        <v-list>
          <v-list-item
            v-for="option in $route.meta.title === 'Administration'
              ? Admin_Options
              : Moderator_OPtions"
            :key="option.id"
            :class="{ active: option.id === current_Option, 'administration-item': true }"
            @click="current_Option = option.id"
          >
            <v-list-item-icon>
              <v-icon>{{ option.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ option.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>
    <div v-if="$route.meta.title === 'Administration'" ref="admin_body">
      <UserPermissions v-if="current_Option === 'user_options'" />
      <TopicOptions v-if="current_Option === 'topic_options'" />
      <RedaktionOptions v-if="current_Option === 'redaktion_options'" />
      <TextOptions v-if="current_Option === 'text_options'" />
    </div>
    <div v-if="$route.meta.title === 'Moderation'" ref="moderator_body">
      <RedaktionOptions v-if="current_Option === 'redaktion_options'" />
    </div>
  </div>
</template>

<script>
import UserPermissions from "@/components/AdministrationsComponents/UserOptions";
import TopicOptions from "@/components/AdministrationsComponents/TopicOptions";
import RedaktionOptions from "@/components/AdministrationsComponents/RedaktionOptions";
import TextOptions from "@/components/AdministrationsComponents/TextOptions";
export default {
  name: "Administration",
  components: {RedaktionOptions, TopicOptions, UserPermissions, TextOptions },

  data: () => ({
    Admin_Options: [
      { title: "User Permissions", icon: "mdi-account-multiple", id: "user_options" },
      { title: "Topics", icon: "mdi-star", id: "topic_options" },
      { title: "Redaktion", icon: "mdi-archive-search", id: "redaktion_options" },
      { title: "Texte", icon: "mdi-text-long", id: "text_options" },
    ],
    Moderator_OPtions: [
      { title: "Redaktion", icon: "mdi-archive-search", id: "redaktion_options" },
    ],
    current_Option: null,
  }),
};
</script>
<style scoped></style>
