<template>
  <div class="forum-view">
    <div class="forum-container">
      <!-- header -->
      <div
        id="forum__head"
        class="forum__head shadow color-white"
        :class="{
          'forum__head--collapsed': forum__head_isCollapsed,
        }"
      >
        <div
          style="white-space: nowrap"
          :class="{
            'heading-one': !forum__head_isCollapsed,
            'heading-two': forum__head_isCollapsed,
          }"
        >
          {{ topic.name }}
        </div>
        <div
          :is="topicInformationTransformed"
          :class="{
            'd-none': forum__head_isCollapsed,
            forum__abstract: true,
          }"
          v-bind="$props"
        />
      </div>

      <!-- content -->
      <div class="forum__content">
        <Feed
          id="forum__content"
          ref="feed"
          class="content__feed"
          :postcards="postcards"
          :isEndReached="isEndReached"
          @scroll="handleScroll"
        />

        <div class="feed__info-btn">
          <Button
            class="info-btn__button color-primary"
            :icon="feed__info_isCollapsed ? 'chevronLeft' : 'chevronRight'"
            label_text=""
            is-cross
            @click.native="toggleBgInfo"
          />
          <!-- editor toggle button -->
          <Button
            v-if="
              loggedInUser != null &&
              loggedInUser.level_of_user !== 'normal' &&
              this.topic.topic_id !== -1
            "
            class="info-btn__button color-primary"
            :icon="feed__info_isEditable ? 'mdi-pencil-off' : 'mdi-pencil'"
            label_text=""
            is-cross
            @click.native="toggleEditTopicText"
          />
          <p class="info-btn__heading" :class="{ 'd-none': !feed__info_isCollapsed }">
            Hintergrundinfos
          </p>
        </div>

        <div
          class="feed__info color-light-grey"
          :class="{
            'feed__info--collapsed': feed__info_isCollapsed,
          }"
        >
          <p class="heading-three">Hintergrundinfos:</p>
          <div
            :is="topicInformationTransformed"
            :class="{ 'd-none': feed__info_isEditable }"
            v-bind="$props"
          />
          <!-- editor -->
          <div :class="{ 'd-none': !feed__info_isEditable }">
            <ckeditor :editor="editor" v-model="topicInformationText" :config="editorConfig" />
            <v-btn
              elevation="1"
              outlined
              class="mb-3 approvePost"
              @click="sendUpdatedTopicInformation()"
              >speichern</v-btn
            >
          </div>
        </div>
      </div>
    </div>
    <!-- write postcard -->
    <Button
      icon="writePostcard"
      label_text="Postkarte schreiben"
      :is-icon="true"
      :class="{
        writePostcard_forum: true,
        writePostcard_forum_position_right: feed__info_isCollapsed,
      }"
      @click.native="
        $router.push({ name: 'createPostcard', params: { id: topic.topic_id } }), sendMatomoEvent()
      "
      @click="updateNav()"
    />
  </div>
</template>

<script>
import Feed from "@/components/Feed";
import Button from "@/components/Button";
import API from "@/services/api";
import { getLoggedInUser } from "@/services/loggedIn";
import { toBoolean } from "../../../client-hubbel/src/services/utils";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import config from "@/config.json";

export default {
  name: "ForumView",
  components: {
    Button,
    Feed,
  },
  data: () => ({
    topic: {},
    postcards: [],
    topicInformationText: "",

    forum__head_isCollapsed: false,
    feed__info_isCollapsed: false,
    feed__info_isEditable: false,
    isEndReached: true,

    editor: ClassicEditor,
    editorConfig: config.editor,
  }),
  computed: {
    topicInformationTransformed() {
      // The topic information comes from a mysql database but contains vue-specific tags like <router-link>
      // To display those tags correctly, they have to be transformed by being rendered in an inner template
      // For the rich text editor the template must be wrapped in a parent div, because the user can't ensure this (this is a vue2 issue)
      return {
        template: `<div>${this.topic.information}</div>`,
        props: this.$options.props,
      };
    },
    loggedInUser() {
      return JSON.parse(getLoggedInUser());
    },
  },

  beforeMount() {
    (async () => {
      if (this.$route.params.topic) {
        await API.getTopicByName(this.$route.params.topic).then((res) => {
          this.topic = res.data[0];
          this.topicInformationText = this.topic.information;
        });
        this.getInitialPosts();
        document.title = this.topic.name;
      } else if (this.$route.params.post_id) {
        let post = (await API.getPostById(this.$route.params.post_id)).data;
        this.renderPost(post);
        this.topic = {
          name: "Einzelne Postkarte",
          topic_id: -1,
        };
        this.topic.information =
          "<div><div>Hier wird nur eine einzelne Postkarte angezeigt.</div></div>";
      } else {
        this.topic = {
          name: "Alle Postkarten",
          topic_id: -1,
        };
        this.getInitialPosts();
        this.topic.information =
          "<div><div>Hier werden Dir alle Postkarten im hubbel unabhängig von ihrem Thema nach Datum sortiert angezeigt. Die oberste Postkarte ist also immer die neuste. Du kannst auf jeder Postkarte rechts unten sehen welchem Thema sie ansonsten zugeordnet ist.<br><br>Zuletzt geändert: 02.08.2022<br>Themenpat*in: hubbel Redaktion<br><br><br><br><br><br></div></div>";
        document.title = this.topic.name;
      }
    })();
  },

  mounted() {
    document.getElementById("forum__content").addEventListener("scroll", this.handleScroll);
    this.feed__info_isCollapsed = window.innerWidth <= 960; // collapse on mobile
  },

  beforeDestroy() {
    document.getElementById("forum__content").removeEventListener("scroll", this.handleScroll);
    //TODO: https://stackoverflow.com/questions/68588188/can-not-get-element-by-getelementbyid-in-beforedestroy-lifecycle-vue
  },
  methods: {
    sendMatomoEvent() {
      if (!toBoolean(process.env.VUE_APP_USE_MATOMO)) return;
      window._paq.push([
        "trackEvent",
        "CreatePostcard on hubbel",
        "Click 'Write Postcard' Button",
        "'Write Postcard' clicked.",
      ]);
    },
    loggedIn() {
      return getLoggedInUser() !== null;
    },
    updateNav() {
      this.$emit("update_Nav");
    },
    handleScroll() {
      //handle topbar collapse
      const forum__head = document.getElementById("forum__head");
      let forum__content = document.getElementById("forum__content");
      if (this.postcards.length > 1) {
        // kann man bestimmt schöner Lösen, weiß aber gerade nicht wie
        if (forum__content.scrollTop > 100) this.forum__head_isCollapsed = true;
        else if (forum__head.style.height !== "200px") this.forum__head_isCollapsed = false;
      }
      //load more posts
      if (!this.$route.params.post_id) {
        if (
          forum__content.scrollTop + forum__content.clientHeight >=
          forum__content.scrollHeight * 0.75
        ) {
          API.getNextPostsforTopic(
            this.topic.topic_id,
            3,
            this.postcards.length,
            this.postcards[0].id
          ).then((res) => {
            res.data.forEach((postObj) => this.renderPost(postObj));
            if (res.data.length <= 0) {
              this.isEndReached = res.data.length <= 0;
            }
          });
        }
      }
    },

    toggleBgInfo() {
      this.feed__info_isCollapsed = !this.feed__info_isCollapsed;
    },
    toggleEditTopicText() {
      this.feed__info_isEditable = !this.feed__info_isEditable;
      if (!this.feed__info_isEditable) {
        this.topic.information = this.topicInformationText;
      }
    },
    renderPost(postObj) {
      if (this.postcards.some((e) => e.id === postObj.post_id)) {
        return;
      }

      function getSender() {
        if (postObj.sender_name) {
          return postObj.sender_name;
        } else if (postObj.user.team && postObj.user.team.length > 0) {
          return postObj.user.team;
        } else if (postObj.user.last_name.length <= 0) {
          return postObj.user.first_name;
        } else {
          return postObj.user.first_name + " " + Array.from(postObj.user.last_name)[0] + ".";
        }
        //TODO maybe keep full name here for moderators
      }

      this.postcards.push({
        id: postObj.post_id,
        readOnly: true,
        children: postObj.children,
        approved: postObj.approved,
        information: postObj.information,
        category: postObj.category,
        sender_verified: postObj.user.verified,
        sender_team: postObj.user.team,
        sender: getSender(),
        sender_name: postObj.sender_name,
        subject: postObj.topic.name,
        allTopicNames: postObj.allTopicNames,
        text: postObj.text,
        date: postObj.createdAt.split("T")[0] + " " + postObj.createdAt.split(/[T.]/)[1],
        attachments: postObj.Attachments,
        isSaved: postObj.isSaved,
        sentFrom: postObj.sentFrom,
        show: false,
      });
    },

    getInitialPosts() {
      API.getNextPostsforTopic(this.topic.topic_id, 5, 0, -1).then((res) =>
        res.data.forEach((postObj) => this.renderPost(postObj))
      );
    },

    sendUpdatedTopicInformation() {
      API.updateTopicInformation(this.topic.topic_id, this.topicInformationText).then((res) => {
        console.log(res.data?.message);
        this.toggleEditTopicText();
      });
    },
  },
};
</script>
