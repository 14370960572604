<template>
  <div class="custom-page">
    <h1>Test</h1>
    <div class="custom-page__content">
      <p>Diese Testseite wurde überschrieben.</p>
      <TestComponent />
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import TestComponent from "@/components/Test";

export default {
  name: "Test",
  components: { Footer, TestComponent },
};
</script>
