<template>
  <v-container class="color-light-grey pt-3 pb-3" fluid="fluid">
    Text aus einer überschriebenen Component.
  </v-container>
</template>

<script>
export default {
  name: "Test",
  props: {
    bottom: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
