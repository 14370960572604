import axios from "axios";
import { authHeader } from "@/services/auth-header";
import { baseURL } from "@/services/api";

const AdminApi = axios.create({
  baseURL: baseURL + "api/admin/",
  headers: {
    "Content-type": "application/json",
  },
});

export default {
  async updateUserPermissions(user_id, level_of_user) {
    return await AdminApi.post(
      "updateUserPermissions",
      { user_id: user_id, level_of_user: level_of_user },
      {
        headers: { Authorization: `Bearer ` + authHeader() },
      }
    );
  },

  async updateUserVerification(user_id, verified) {
    return await AdminApi.post(
      "updateUserVerification",
      { user_id: user_id, verified: verified },
      {
        headers: { Authorization: `Bearer ` + authHeader() },
      }
    );
  },
  async updateUserSubscription(user_id, get_emails_editor) {
    return await AdminApi.post(
      "updateUserSubscription",
      { user_id: user_id, get_emails_editor: get_emails_editor },
      {
        headers: { Authorization: `Bearer ` + authHeader() },
      }
    );
  },
  async updateUserTeam(user_id, team) {
    return await AdminApi.post(
      "updateUserTeam",
      { user_id: user_id, team: team },
      {
        headers: { Authorization: `Bearer ` + authHeader() },
      }
    );
  },
  async getAllUsers() {
    return await AdminApi.get("getAllUsers", {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async getAllTopics() {
    return await AdminApi.get("getAllTopics", {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async updateTopicActive(topic_id, approved) {
    return await AdminApi.post(
      "updateTopicActive",
      { topic_id: topic_id, approved: approved },
      {
        headers: { Authorization: `Bearer ` + authHeader() },
      }
    );
  },
  async updateTopicInformation(topic_id, text) {
    return await AdminApi.patch(
      "updateTopicInformation/" + topic_id,
      { text: text },
      {
        headers: { Authorization: `Bearer ` + authHeader() },
      }
    );
  },
  async updateTopicSubheading(topic_id, text) {
    return await AdminApi.post(
      "updateTopicSubheading",
      { topic_id: topic_id, text: text },
      {
        headers: { Authorization: `Bearer ` + authHeader() },
      }
    );
  },
  async updateTopicName(topic_id, text) {
    return await AdminApi.post(
      "updateTopicName",
      { topic_id: topic_id, text: text },
      {
        headers: { Authorization: `Bearer ` + authHeader() },
      }
    );
  },
  async getAllTexts() {
    return await AdminApi.get("getAllTexts", {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },
  async updateTextContent(text_id, content) {
    return await AdminApi.patch(
      "updateTextContent/" + text_id,
      { text_id, content },
      {
        headers: { Authorization: `Bearer ` + authHeader() },
      }
    );
  },
};
