var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"attachment-comp"},[_c('div',{staticClass:"attachment__heading"},[_vm._v(" "+_vm._s(_vm.attachments.length + (_vm.attachments.length === 1 ? " Anhang" : " Anhänge"))+" ")]),_vm._l((_vm.attachments),function(attachment,index){return _c('v-card',{key:index,staticClass:"attachment__thumbnail"},[_c('div',{on:{"click":function($event){return $event.stopPropagation()}}},[(!_vm.readOnly)?_c('Button',{staticClass:"thumbnail__delete-btn color-light-grey",attrs:{"is-action":"","icon":"trash"},nativeOn:{"click":function($event){return _vm.$emit('deleteAttachment', index)}}}):_vm._e(),_c('div',{on:{"click":function($event){return _vm.openAttachmentModal(index)}}},[(
            attachment.type === 'jpeg' ||
            attachment.type === 'png' ||
            attachment.type === 'PNG' ||
            attachment.type === 'jpg' ||
            attachment.type === 'JPG' ||
            attachment.type === 'svg' ||
            attachment.type === 'SVG' ||
            attachment.type === 'gif' ||
            attachment.type === 'jfif' ||
            attachment.type === 'GIF'
          )?_c('v-img',{staticClass:"thumbnail__image",attrs:{"src":attachment.src}}):_vm._e(),(attachment.type === 'pdf')?_c('div',{staticClass:"thumbnail__image thumbnail__image--icon"},[_c('pdf')],1):_vm._e(),_c('v-card-title',{staticClass:"thumbnail__title font-regular"},[_vm._v(" "+_vm._s(attachment.name)+" ")])],1),_c('div',{staticClass:"modal modal--attachment",attrs:{"id":'AttachmentModal' + index + '-' + _vm.postcard_id}},[_c('div',{staticClass:"modal-content"},[_c('span',{staticClass:"close",on:{"click":function($event){return _vm.closeAttachmentModal($event, index)}}},[_c('v-icon',[_vm._v("$x")])],1),_c('p',{staticClass:"heading-three font-dark-grey bookmark-modal-heading"},[_vm._v(" "+_vm._s(attachment.name)+" ")]),_c('div',{staticClass:"modal__content font-dark-grey font-medium"},[(
                attachment.type === 'jpeg' ||
                attachment.type === 'png' ||
                attachment.type === 'PNG' ||
                attachment.type === 'jpg' ||
                attachment.type === 'JPG' ||
                attachment.type === 'svg' ||
                attachment.type === 'SVG' ||
                attachment.type === 'gif' ||
                attachment.type === 'jfif' ||
                attachment.type === 'GIF'
              )?_c('v-img',{attrs:{"contain":"","src":attachment.src,"max-height":"35vw"}}):_vm._e(),(attachment.type === 'pdf')?_c('div',[_c('iframe',{staticClass:"modal__pdf",attrs:{"src":attachment.src + '#toolbar=0'}})]):_vm._e()],1),_c('Button',{attrs:{"label_text":"Schließen","is-primary":true},nativeOn:{"click":function($event){return _vm.closeAttachmentModal($event, index)}}}),_c('Button',{staticClass:"ml-5",attrs:{"label_text":"In neuem Tab öffnen","is-primary":true},nativeOn:{"click":function($event){return _vm.openAttachmentInNewTab($event, attachment.src)}}})],1)])],1)])}),(!_vm.readOnly)?_c('v-card',{staticClass:"attachment__thumbnail attachment__thumbnail--add",on:{"click":function($event){return _vm.$emit('openInput')}}},[_c('v-icon',[_vm._v(" $plus ")])],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }