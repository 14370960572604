<template>
  <router-link :to="to" :class="{ active: active, 'menu-item-comp': true }">
    <p>
      {{ title }}
    </p>
  </router-link>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    to: String,
    title: String,
    active: Boolean,
  },
};
</script>
