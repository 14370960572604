import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";

import {
  ArrowLeft,
  ArrowRight,
  Bookmark,
  BookmarkPlus,
  Calendar,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Download,
  Edit,
  Filter,
  Heart,
  HelpCircle,
  Home,
  Image,
  Lightbulb,
  LogIn,
  LogOut,
  Menu,
  MoreHorizontal,
  Paperclip,
  Plus,
  Printer,
  Search,
  Share2,
  Star,
  Trash,
  X,
  XCircle,
} from "lucide-vue";
import Pdf from "@/components/Icons/pdf";
import Pin from "@/components/Icons/Pin";
import PinFilled from "@/components/Icons/PinFilled";
import Avatar from "@/components/Icons/Avatar";
import BookmarkFilled from "@/components/Icons/BookmarkFilled";
import Postcard from "@/components/Icons/Postcard";
import WritePostcard from "@/components/Icons/WritePostcard";
import SearchForPostcard from "@/components/Icons/SearchForPostcards";

const MY_ICONS = {
  menu: {
    component: Menu,
  },
  paperclip: {
    component: Paperclip,
  },
  bookmark: {
    component: Bookmark,
  },
  bookmarkPlus: {
    component: BookmarkPlus,
  },
  calendar: {
    component: Calendar,
  },
  edit: {
    component: Edit,
  },
  filter: {
    component: Filter,
  },
  heart: {
    component: Heart,
  },
  home: {
    component: Home,
  },
  chevronUp: {
    component: ChevronUp,
  },
  chevronDown: {
    component: ChevronDown,
  },
  chevronLeft: {
    component: ChevronLeft,
  },
  chevronRight: {
    component: ChevronRight,
  },
  xCircle: {
    component: XCircle,
  },
  plus: {
    component: Plus,
  },
  printer: {
    component: Printer,
  },
  trash: {
    component: Trash,
  },
  image: {
    component: Image,
  },
  login: {
    component: LogIn,
  },
  logout: {
    component: LogOut,
  },
  download: {
    component: Download,
  },
  moreHorizontal: {
    component: MoreHorizontal,
  },
  x: {
    component: X,
  },
  helpCircle: {
    component: HelpCircle,
  },
  check: {
    component: Check,
  },
  share: {
    component: Share2,
  },
  lightbulb: {
    component: Lightbulb,
  },
  arrowLeft: {
    component: ArrowLeft,
  },
  arrowRight: {
    component: ArrowRight,
  },
  search: {
    component: Search,
  },
  avatar: {
    component: Avatar,
  },
  pin: {
    component: Pin,
  },
  pinFilled: {
    component: PinFilled,
  },
  bookmarkFilled: {
    component: BookmarkFilled,
  },
  postcard: {
    component: Postcard,
  },
  writePostcard: {
    component: WritePostcard,
  },
  searchForPostcard: {
    component: SearchForPostcard,
  },
  pdf: {
    component: Pdf,
  },
  star: {
    component: Star,
  },
};

Vue.use(Vuetify);

import colors from "../../../client-shared/scss/variables.scss";

export default new Vuetify({
  icons: {
    values: MY_ICONS,
    iconfont: "mdiSvg",
  },
  theme: {
    themes: {
      light: {
        primary: colors.primary,
        secondary: colors.secondary,
        anchor: colors.primary_darken50,
      },
    },
  },
});
