<template>
  <div id="options" class="d-inline-flex context-menu-comp">
    <div class="share">
      <ShareNetwork
        network="facebook"
        :url="url"
        title="Schau mal hier: "
        :description="writeDescription(topic, sender)"
        @click.native="dontOpen"
      >
        <v-img
          src="../../../client-shared/img/facebook.svg"
          alt="Teilen per Facebook"
          width="24"
          tabindex="1"
        />
      </ShareNetwork>
    </div>
    <div class="share">
      <ShareNetwork
        network="whatsapp"
        :url="url"
        title="Schau mal hier: "
        :description="writeDescription(topic, sender)"
        @click.native="dontOpen"
      >
        <v-img
          src="../../../client-shared/img/whatsapp.svg"
          alt="Teilen per Whatsapp"
          width="24"
          tabindex="1"
        />
      </ShareNetwork>
    </div>
    <div class="share">
      <ShareNetwork
        network="email"
        :url="url"
        title="Sieh dir diesen Beitrag mal an "
        :description="writeDescription(topic, sender)"
        @click.native="dontOpen"
      >
        <v-img
          src="../../../client-shared/img/mail.svg"
          alt="Teilen per Email"
          width="24"
          tabindex="1"
        />
      </ShareNetwork>
    </div>
    <div class="share">
      <a>
        <v-img
          src="../../../client-shared/img/copy_2_blue.svg"
          alt="Link kopieren"
          width="24"
          tabindex="1"
          @click.native="copyLink"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContextMenu",
  props: {
    topic: { type: String },
    sender: { type: String },
    id: { type: Number },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    url() {
      let wholeUrl = window.location.href;
      let baseUrl = wholeUrl.replace(window.location.pathname, "");
      return baseUrl + "/post/" + this.id;
    },
  },
  methods: {
    copyLink(event) {
      event.stopPropagation();
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.url);
        alert("Link kopiert!");
      } else {
        alert("Dein Browser unterstützt diese Funktion nicht.");
      }
    },
    dontOpen(event) {
      event.stopPropagation();
    },
    writeDescription(topic, sender) {
      return "Eine spannende Postkarte zum Thema " + topic + " von " + sender;
    },
  },
};
</script>
