<template>
  <div class="terms accept-terms-input-comp">
    <p>Der hubbel ist begeistert, dass du dich registrieren willst!</p>
    <p>
      Damit du wei&szlig;t, worauf du dich einl&auml;sst, hier ein paar wichtige Informationen zu
      unseren <router-link to="/nutzungsbedingungen"> Nutzungsbedingungen </router-link> in
      Kurzfassung:
    </p>
    <ol>
      <li>
        Der hubbel ist aktuell noch in der Testphase. Das bedeutet:<br />
        <ul>
          <li>
            Manche funktionieren Dinge vielleicht noch nicht perfekt, wir arbeiten aber fleißig
            daran. Wenn dir ein Fehler auffällt, schreib uns gerne eine Mail.
          </li>
          <li>
            Du erhältst keine Bestätigungsemail, wenn du dich registriert hast, es kann einfach
            direkt losgehen!
          </li>
          <li>
            Man kann aktuell das eigene Passwort noch nicht zurücksetzen. Wenn du dein Passwort
            vergessen haben solltest, schreib uns eine Mail.
          </li>
        </ul>
      </li>
      <br />
      <li>
        Speicherung deiner Daten<br />
        <ul>
          <li>
            Du musst mindestens 18 Jahre alt sein oder mindestens 14 Jahre und eine Zustimmung
            deiner Eltern haben
          </li>
          <li>
            Der hubbel läuft aktuell auf Servern des Lehrstuhls für Psychologische Ergonomie der
            Universität Würzburg. Dort werden die Postkarten und ihre Anhänge sowie die
            personenbezogenen Daten, die du bei der Registrierung angibst, gespeichert.
          </li>
          <li>Dein Passwort wird verschlüsselt gespeichert.</li>
          <li>Wir geben deine Daten nicht an Dritte weiter und nutzen sie nicht für Werbung.</li>
          <li>
            Du kannst deinen Account und die dazugehörigen Postkarten jederzeit über „Mein Bereich“
            löschen. Alternativ kannst du uns eine Mail mit deinem Namen und deiner Emailadresse
            schicken und wir löschen ihn für dich.
          </li>
        </ul>
      </li>
      <br />
      <li>
        Der hubbel hat eine Redaktion<br />
        <ul>
          <li>
            Postkarten werden zuerst von unserer Redaktion auf Hassrede, Richtigkeit und
            gegebenenfalls Rechtschreibung geprüft bevor sie öffentlich werden. Diese Redaktion
            besteht aktuell aus Franzisca Maas, zwei wissenschaftlichen Hilfskräften und drei
            engagierten Hubländer*innen.
          </li>
          <li>Wir versuchen die Postkarten möglichst schnell freizuschalten!</li>
          <li>
            Wenn du Interesse hast, die Redaktion zu unterstützen, schreib uns eine Mail an
            <a href="mailto:hubbelredaktion@protonmail.com">hubbelredaktion@protonmail.com</a>
          </li>
        </ul>
      </li>
      <br />
      <li>
        Postkarten schreiben<br />
        <ul>
          <li>
            Wenn du dich registriert hast, kannst du Postkarten mit Fragen oder Informationen
            schreiben. Versuche Postkarten möglichst einfach zu formulieren und beschreibe woher du
            deine Information hast.
          </li>
          <li>
            Sobald eine Postkarte von der Redaktion freigegeben wurde, ist sie für alle Menschen
            sichtbar, die die hubbel Webseite oder den Briefkasten-hubbel besuchen. Man benötigt
            keinen Account, um Postkarten lesen zu können.
          </li>
          <li>
            Deine Postkarten werden mit deinem Vornamen und abgekürzten Nachnamen angezeigt, z.B.
            „Franzisca M.“
          </li>
          <li>
            Bitte verwende keine Inhalte, deren Rechte du nicht besitzt (z.B. Fotos) oder die die
            Rechte anderer beeinträchtigen könnten.
          </li>
        </ul>
      </li>
    </ol>
    <br />
    <p>
      Kontaktinformationen:<br />
      Franzisca Maas<br />
      Lehrstuhl f&uuml;r Psychologische Ergonomie, Universit&auml;t W&uuml;rzburg<br />
      Email:
      <a href="mailto:franzisca.maas@uni-wuerzburg.de">franzisca.maas@uni-wuerzburg.de</a>
    </p>
    <div class="accept_terms_checkbox">
      <v-checkbox
        ref="accept_terms"
        v-model="accept_terms"
        :hint="hint"
        :rules="[rules.required]"
      />
      <span
        >Ich habe die
        <router-link to="/nutzungsbedingungen">Nutzungsbedingungen</router-link> gelesen und stimme
        der Nutzung des hubbel unter diesen Bedingungen zu.</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "AcceptTerms",
  props: {
    label: String,
    hint: String,
  },
  data() {
    return {
      accept_terms: null,
      rules: {
        required: (value) => !!value || "Die Nutzungsbesdingungen müssen akzeptiert werden.",
      },
    };
  },
  watch: {
    accept_terms(val) {
      this.$emit("input", val);
    },
  },
  methods: {
    validate(bool) {
      return this.$refs.accept_terms.validate(bool);
    },
    getValue() {
      return this.accept_terms;
    },
  },
};
</script>
