<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.8" y="4.8" width="22.4" height="14.4" rx="1.6" stroke="#5F5F5F" stroke-width="1.6" />
    <path d="M4 8.79999H12" stroke="#5F5F5F" stroke-width="1.6" stroke-linecap="round" />
    <path d="M4 12H12" stroke="#5F5F5F" stroke-width="1.6" stroke-linecap="round" />
    <path d="M4 15.2H9.6" stroke="#5F5F5F" stroke-width="1.6" stroke-linecap="round" />
    <rect x="17.6001" y="7.20001" width="3.2" height="4" rx="0.8" fill="#5F5F5F" />
  </svg>
</template>

<script>
export default {
  name: "Postcard",
};
</script>
