<template>
  <div class="success-view">
    <v-icon size="300px" color="secondary"> $check </v-icon>
    <h1 class="heading-two font-black success__heading">Deine Postkarte wurde verschickt!</h1>
    <p class="font-large font-dark-grey success__text">
      Nach der Prüfung durch die Redaktion wird sie unter dem gewählten Thema erscheinen.
    </p>
    <Button
      class="justify-center"
      :is-primary="true"
      label_text="Zurück zur Startseite"
      @click.native="goToHome()"
    />
  </div>
</template>

<script>
import Button from "@/components/Button";
export default {
  name: "Success",
  components: { Button },
  methods: {
    goToHome() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
