<template>
  <div>
    <div class="custom-page" v-html="textContent" />
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import API from "@/services/api";

export default {
  name: "AboutUs",
  components: { Footer },
  data() {
    return {
      textName: "about",
      textContent: "placeholder for db content",
    };
  },
  beforeMount() {
    (async () => {
      await API.getTextByName(this.textName).then((res) => {
        let text = res.data[0];
        this.textContent = text.content;
      });
    })();
  },
};
</script>
