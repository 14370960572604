<template>
  <div class="maincontent single-postcard-view">
    <div class="postcard_with_buttons">
      <PostCard :postcard_info="postcard" class="postcard" :single-postcard="true" />
      <PostcardBar
        v-if="postcard.approved"
        :id="postcard.id"
        ref="postcardBar"
        v-model="postcard.isSaved"
        class="button_row"
        :bookmarked="postcard.isSaved"
        :sender="postcard.sender"
        :topic="postcard.subject"
        :postcard="postcard"
        @showModal="openBookmarkModal()"
      />
    </div>
    <button id="backToForum" @click="$router.push('/Forum')">
      <v-icon color="#8F8F8F" size="24" class="pt-4 pb-4"> $arrowLeft </v-icon>
      <span>Alle Postkarten zum Thema </span>
      {{ postcard.subject }}
    </button>
  </div>
</template>

<script>
import PostCard from "@/components/PostCard";
import Api from "@/services/api";
import PostcardBar from "@/components/PostcardBar";

export default {
  name: "SinglePostcard",
  components: { PostCard, PostcardBar },
  data() {
    return {
      postcardId: null,
      postcard: null,
    };
  },

  beforeMount() {
    this.postcardId = this.$route?.params?.postcard_id || null;
    //TODO extract this into an abstract "getIdFromQueryParams", and maybe pass the name of the id
    //TODO postcardId can probably replaced with a local var instead of having it in data() -> const postcardId

    Api.getPostById(this.postcardId).then((res) => {
      this.renderPostcard(res.data);
    });
  },

  methods: {
    renderPostcard(postObj) {
      this.postcard = {
        id: postObj.post_id,
        readOnly: true,
        approved: postObj.approved,
        category: postObj.category,
        information: postObj.information,
        sender: postObj.user.first_name + " " + postObj.user.last_name,
        subject: postObj.topic.name,
        text: postObj.text,
        date: postObj.createdAt.split("T")[0] + " " + postObj.createdAt.split(/[T.]/)[1],
        attachments: postObj.Attachments,
        isSaved: postObj.isSaved,
        sentFrom: postObj.sentFrom,
        show: false,
      };
    },
  },
};
</script>
