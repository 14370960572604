<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30 18V16H24V26H26V22H29V20H26V18H30Z" fill="#5F5F5F" />
    <path
      d="M19 26H15V16H19C19.7954 16.0008 20.558 16.3171 21.1204 16.8796C21.6829 17.442 21.9992 18.2046 22 19V23C21.9992 23.7954 21.6829 24.558 21.1204 25.1204C20.558 25.6829 19.7954 25.9992 19 26ZM17 24H19C19.2651 23.9997 19.5193 23.8943 19.7068 23.7068C19.8943 23.5193 19.9997 23.2651 20 23V19C19.9997 18.7349 19.8943 18.4807 19.7068 18.2932C19.5193 18.1057 19.2651 18.0003 19 18H17V24Z"
      fill="#5F5F5F"
    />
    <path
      d="M11 16H6V26H8V23H11C11.5302 22.9992 12.0384 22.7882 12.4133 22.4133C12.7882 22.0384 12.9992 21.5302 13 21V18C12.9995 17.4697 12.7886 16.9613 12.4136 16.5864C12.0387 16.2114 11.5303 16.0005 11 16V16ZM8 21V18H11L11.001 21H8Z"
      fill="#5F5F5F"
    />
    <path
      d="M22 14V10C22.0035 9.86858 21.9785 9.73795 21.9268 9.61711C21.875 9.49627 21.7976 9.38809 21.7 9.3L14.7 2.3C14.612 2.20235 14.5038 2.12495 14.3829 2.07316C14.2621 2.02137 14.1314 1.99641 14 2H4C3.47005 2.00159 2.96227 2.21281 2.58753 2.58754C2.2128 2.96227 2.00158 3.47006 2 4V28C2 28.5304 2.21071 29.0391 2.58579 29.4142C2.96086 29.7893 3.46957 30 4 30H20V28H4V4H12V10C12.0016 10.53 12.2128 11.0377 12.5875 11.4125C12.9623 11.7872 13.4701 11.9984 14 12H20V14H22ZM14 10V4.4L19.6 10H14Z"
      fill="#5F5F5F"
    />
  </svg>
</template>

<script>
export default {
  name: "Pdf",
};
</script>
