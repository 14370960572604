import axios from "axios";
import { authHeader } from "@/services/auth-header";
import { baseURL } from "@/services/api";

const AuthApi = axios.create({
  baseURL: baseURL + "api/auth/",
  headers: {
    "Content-type": "application/json",
  },
});

export default {
  async login(email, password) {
    return await AuthApi.post("/login", {
      email: email,
      password: password,
    }).then((res) => {
      document.cookie = "user=" + JSON.stringify(res.data.user) + " ; path=/; SameSite=Lax";
      return true;
    });
  },
  async register(form) {
    return await AuthApi.post("register", form).then((res) => {
      if (res.data) {
        return this.login(form.email, form.password);
      }
    });
  },
  async checkEmailUnique(email) {
    return await AuthApi.post("checkEmailUnique", email);
  },
  async changePassword(oldPassword, newPassword) {
    let data = {
      old: oldPassword,
      new: newPassword,
    };
    return await AuthApi.post(`changePassword`, data, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async logout() {
    document.cookie = 'user=;expires=Thu, 01 Jan 1970 00:00:01 GMT;"; SameSite=Lax';
    return true;
  },

  async verifyPermissions(level_of_user) {
    return await AuthApi.post(
      "verifyPermissions",
      { level_of_user: level_of_user },
      {
        headers: { Authorization: `Bearer ` + authHeader() },
      }
    );
  },
};
