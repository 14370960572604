<template>
  <v-container class="attachment-comp">
    <div class="attachment__heading">
      {{ attachments.length + (attachments.length === 1 ? " Anhang" : " Anhänge") }}
    </div>

    <v-card v-for="(attachment, index) in attachments" :key="index" class="attachment__thumbnail">
      <div @click="$event.stopPropagation()">
        <Button
          v-if="!readOnly"
          is-action
          icon="trash"
          class="thumbnail__delete-btn color-light-grey"
          @click.native="$emit('deleteAttachment', index)"
        />

        <div @click="openAttachmentModal(index)">
          <v-img
            v-if="
              attachment.type === 'jpeg' ||
              attachment.type === 'png' ||
              attachment.type === 'PNG' ||
              attachment.type === 'jpg' ||
              attachment.type === 'JPG' ||
              attachment.type === 'svg' ||
              attachment.type === 'SVG' ||
              attachment.type === 'gif' ||
              attachment.type === 'jfif' ||
              attachment.type === 'GIF'
            "
            :src="attachment.src"
            class="thumbnail__image"
          />
          <div v-if="attachment.type === 'pdf'" class="thumbnail__image thumbnail__image--icon">
            <pdf />
          </div>

          <v-card-title class="thumbnail__title font-regular">
            {{ attachment.name }}
          </v-card-title>
        </div>
        <div :id="'AttachmentModal' + index + '-' + postcard_id" class="modal modal--attachment">
          <div class="modal-content">
            <span class="close" @click="closeAttachmentModal($event, index)"
              ><v-icon>$x</v-icon></span
            >
            <p class="heading-three font-dark-grey bookmark-modal-heading">
              {{ attachment.name }}
            </p>
            <div class="modal__content font-dark-grey font-medium">
              <v-img
                v-if="
                  attachment.type === 'jpeg' ||
                  attachment.type === 'png' ||
                  attachment.type === 'PNG' ||
                  attachment.type === 'jpg' ||
                  attachment.type === 'JPG' ||
                  attachment.type === 'svg' ||
                  attachment.type === 'SVG' ||
                  attachment.type === 'gif' ||
                  attachment.type === 'jfif' ||
                  attachment.type === 'GIF'
                "
                contain
                :src="attachment.src"
                max-height="35vw"
              />
              <div v-if="attachment.type === 'pdf'">
                <iframe :src="attachment.src + '#toolbar=0'" class="modal__pdf" />
              </div>
            </div>
            <Button
              label_text="Schließen"
              :is-primary="true"
              @click.native="closeAttachmentModal($event, index)"
            />
            <Button
              class="ml-5"
              label_text="In neuem Tab öffnen"
              :is-primary="true"
              @click.native="openAttachmentInNewTab($event, attachment.src)"
            />
          </div>
        </div>
      </div>
    </v-card>

    <v-card
      v-if="!readOnly"
      class="attachment__thumbnail attachment__thumbnail--add"
      @click="$emit('openInput')"
    >
      <v-icon> $plus </v-icon>
    </v-card>
  </v-container>
</template>

<script>
import Pdf from "@/components/Icons/pdf";
import Button from "@/components/Button";

export default {
  name: "Attachment",
  components: { Button, Pdf },
  props: {
    attachments: Array,
    postcard_id: Number,
    readOnly: Boolean,
  },
  methods: {
    openAttachmentModal(index) {
      const modal = document.getElementById("AttachmentModal" + index + "-" + this.postcard_id);
      modal.style.display = "block";
      window._paq.push([
        "trackEvent",
        "Attachment",
        "Open Attachment",
        "Attachment of Postcard " + this.postcard_id + " opened (Index: " + index + ")",
      ]);
    },
    closeAttachmentModal(event, index) {
      const modal = document.getElementById("AttachmentModal" + index + "-" + this.postcard_id);
      modal.style.display = "none";
    },
    openAttachmentInNewTab(event, url) {
      console.log(url);
      window.open(url, "_blank").focus();
    },
  },
};
</script>
