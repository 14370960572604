<template>
  <v-app class="app">
    <Navbar ref="Navbar" :name="$route.name" />
    <div class="app__content">
      <router-view :key="$route.fullPath" @ProfilePictureChange="changeNavProfilePic()" />
    </div>
    <vue-confirm-dialog class="vue-confirm-dialog--test" />
  </v-app>
</template>
<script>
import Navbar from "@/components/Navbar";

export default {
  components: {
    Navbar,
  },

  methods: {
    changeNavProfilePic() {
      this.$refs.Navbar.changeProfilePicture();
    },
  },
};
</script>
