<template>
  <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.1388 5.60001H2.59356C2.17092 5.60001 1.76559 5.76858 1.46674 6.06864C1.16789 6.36869 1 6.77566 1 7.20001V18.4C1 18.8244 1.16789 19.2313 1.46674 19.5314C1.76559 19.8314 2.17092 20 2.59356 20H21.7162C22.1389 20 22.5442 19.8314 22.843 19.5314C23.1419 19.2313 23.3098 18.8244 23.3098 18.4V12.8"
      stroke="#000000"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M4.18701 9.60001H12.1548" stroke="#000000" stroke-width="1.6" stroke-linecap="round" />
    <path d="M4.18701 12.8H11.358" stroke="#000000" stroke-width="1.6" stroke-linecap="round" />
    <path d="M4.18701 16H9.76446" stroke="#000000" stroke-width="1.6" stroke-linecap="round" />
    <path
      d="M22.1147 4.49706C22.4317 4.1788 22.8616 4 23.3099 4C23.7581 4 24.1881 4.1788 24.505 4.49706C24.822 4.81532 25.0001 5.24697 25.0001 5.69706C25.0001 6.14714 24.822 6.5788 24.505 6.89706L16.9356 14.4971L13.7485 15.2971L14.5453 12.0971L22.1147 4.49706Z"
      stroke="#000000"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "WritePostcard",
};
</script>
