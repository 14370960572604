<template>
  <div class="card-comp card-comp--dashed">
    <v-card
      class="mx-auto ma-3 card dashed-vector"
      :class="{
        'card--selected': selected,
      }"
    >
      <svg
        class="dashed-svg"
        width="100%"
        height="100%"
        viewBox="0 0 316 201"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        xmlns:serif="http://www.serif.com/"
        style="
          fill-rule: evenodd;
          clip-rule: evenodd;
          stroke-linecap: round;
          stroke-miterlimit: 1.5;
        "
      >
        <g transform="matrix(1,0,0,1,-97.2958,-97.2958)">
          <g transform="matrix(1.03333,0,0,0.975,-3.33333,2.5)">
            <path
              class="dashed-path"
              d="M400,102C400,100.896 399.154,100 398.113,100L101.887,100C100.846,100 100,100.896 100,102L100,298C100,299.104 100.846,300 101.887,300L398.113,300C399.154,300 400,299.104 400,298L400,102Z"
              style="
                fill: none;
                stroke: rgb(95, 95, 95);
                stroke-width: 2.99px;
                stroke-dasharray: 14.93, 11.95, 14.93, 11.95;
                stroke-dashoffset: 2.99;
              "
            />
          </g>
        </g>
      </svg>

      <v-card-title class="card__title">
        <v-icon v-if="icon" x-large>
          {{ icon }}
        </v-icon>
      </v-card-title>

      <v-card-text class="card__text">
        {{ title }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DashedCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    pinned: false,
  }),
};
</script>
