<template>
  <div class="login-view">
    <h1 class="heading-one">Anmelden</h1>
    <v-form>
      <div class="login-container">
        <table>
          <tbody>
            <tr>
              <td>
                <v-text-field
                  id="email"
                  v-model="email"
                  label="Email"
                  outlined
                  placeholder="Beispiel@email.de"
                  required="required"
                />
              </td>
            </tr>
            <tr>
              <td>
                <v-text-field
                  id="passwordField"
                  v-model="password"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show ? 'text' : 'password'"
                  counter
                  hint="Dein Account-Passwort"
                  label="Passwort"
                  outlined
                  @click:append="show = !show"
                  @keydown.enter.native="login"
                />
              </td>
            </tr>
            <tr>
              <td>
                <Button is-primary="is-primary" label_text="Anmelden" @click.native="login" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-form>
    <Footer :bottom="true" />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Button from "@/components/Button";
import AuthApi from "@/services/auth-api";

export default {
  name: "Login",
  components: { Button, Footer },
  data() {
    return {
      show: false,
      email: "",
      password: "",
      rules: {
        required: (value) => !!value || "Es wird ein Passwort benötigt",
        min: (v) => (!!v && v.length >= 8) || "Das Passwort muss mindestens 8 Zeichen lang sein",
      },
    };
  },
  methods: {
    login() {
      AuthApi.login(this.email, this.password)
        .then(async (success) => {
          if (success) {
            this.$emit("ProfilePictureChange");
            return true;
          } else {
            alert(
              "Das eingegebene Passwort oder die E-Mail ist falsch. \n\nHast du dein Passwort vergessen? \nAktuell befinden wir uns noch in der Testphase, daher ist es noch nicht möglich das Passwort eigenständig zu ändern. Wende dich daher bitte via E-Mail an franzisca.maas@uni-wuerzburg.de, damit wir dieses Problem lösen können."
            );
          }
        })
        .then((success) => {
          if (success) this.$router.push("/");
        });
    },
  },
};
</script>
