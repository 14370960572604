<template>
  <div class="user-grid">
    <div class="user-table">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        style="width: 30%; margin-left: 69%"
      />
      <v-data-table
        :search="search"
        hide-default-footer
        disable-pagination
        :headers="table.headers"
        :items="users"
      >
        <template v-slot:item="{ item }">
          <tr
            :class="selected_user?.user_id === item.user_id ? 'active' : ''"
            @click="selected_user = item"
          >
            <td>{{ item.user_id }}</td>
            <td>{{ item.name }}</td>
            <td>
              <v-img
                v-if="item.verified"
                class="verified-icon"
                src="../../../../client-shared/img/verification_hubbel_v01.svg"
                alt="(verified)"
                width="18"
                tabindex="1"
              />
            </td>
            <td>{{ item.email }}</td>
            <td>{{ item.level_of_user }}</td>
            <td>{{ item.get_emails_editor }}</td>
            <td>{{ item.team }}</td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <div class="user-tools">
      <div class="heading-three" style="width: 10vw; margin: auto">Werkzeuge</div>
      <p />
      <v-divider style="margin-left: 5%; width: 90%; border-width: 2px !important" />
      <v-list v-if="selected_user !== null">
        <v-list-item>
          <b>Berechtigung:&nbsp;</b>{{ selected_user.level_of_user }}
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon> $edit </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, i) in ['normal', 'moderator', 'admin']"
                :key="i"
                @click="
                  updateUserPermissions(selected_user.user_id, item).then((res) => {
                    if (res.data) {
                      selected_user.level_of_user = item;
                    }
                  })
                "
              >
                <v-hover
                  ><v-list-item-title>{{ item }}</v-list-item-title></v-hover
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item>
        <v-divider style="margin-left: 5%; width: 90%" />
        <v-list-item>
          <b>Verifiziert:&nbsp;</b>
          <div>
            <v-switch
              v-model="selected_user.verified"
              :label="selected_user.verified ? 'verified' : 'unverified'"
              @click.native.prevent="
                updateUserVerification(selected_user.user_id, selected_user.verified).then(
                  (res) => {
                    if (!res.data) {
                      selected_user.verified = !selected_user.verified;
                    }
                  }
                )
              "
            />
          </div>
        </v-list-item>
        <v-divider style="margin-left: 5%; width: 90%" />
        <v-list-item>
          <b>Subscription:&nbsp;</b>
          <div>
            <v-switch
              v-model="selected_user.get_emails_editor"
              :label="selected_user.get_emails_editor ? 'subscribed' : 'unsubscribed'"
              @click.native.prevent="
                updateUserSubscription(selected_user.user_id, selected_user.get_emails_editor).then(
                  (res) => {
                    if (!res.data) {
                      selected_user.get_emails_editor = !selected_user.get_emails_editor;
                    }
                  }
                )
              "
            />
          </div>
        </v-list-item>
        <v-divider style="margin-left: 5%; width: 90%" />
        <v-list-item>
          <b>Team:&nbsp;</b> {{ selected_user.team ? selected_user.team : "keines" }}
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon> $edit </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="team in teams"
                :key="team"
                @click="
                  updateUserTeam(selected_user.user_id, team).then((res) => {
                    if (res.data) {
                      selected_user.team = team;
                    }
                  })
                "
              >
                <v-hover>
                  <v-list-item-title>{{ team }}</v-list-item-title>
                </v-hover>
              </v-list-item>
              <v-divider style="margin-left: 5%; width: 90%" />
              <v-list-item @click="utilities.dialog = true">
                <v-hover>
                  <v-list-item-title>Neues Team</v-list-item-title>
                </v-hover>
              </v-list-item>
              <v-list-item
                @click="
                  updateUserTeam(selected_user.user_id, '').then((res) => {
                    if (res.data) {
                      selected_user.team = '';
                    }
                  })
                "
              >
                <v-hover>
                  <v-list-item-title>Kein Team</v-list-item-title>
                </v-hover>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-dialog v-model="utilities.dialog" width="auto">
            <v-card>
              <h2>Neues Team Erstellen</h2>
              <div style="width: 90%; margin: auto">
                <v-text-field
                  v-model="utilities.new_team"
                  label="Neues Team"
                  @click:append-outer="
                    updateUserTeam(selected_user.user_id, this.utilities.new_team)
                  "
                >
                  <template v-slot:append>
                    <v-icon
                      @click="
                        updateUserTeam(selected_user.user_id, utilities.new_team)
                          .then((res) => {
                            if (res.data) {
                              teams.push(utilities.new_team);
                              selected_user.team = utilities.new_team;
                            }
                          })
                          .then(() => {
                            utilities.new_team = '';
                            utilities.dialog = false;
                          })
                      "
                    >
                      mdi-plus
                    </v-icon>
                  </template>
                </v-text-field>
              </div>
            </v-card>
          </v-dialog>
        </v-list-item>
        <v-divider style="margin-left: 5%; width: 90%" />
      </v-list>
    </div>
  </div>
</template>

<script>
import AdminApi from "@/services/admin-api";
export default {
  name: "UserPermissions",
  components: {},
  data: () => ({
    table: {
      headers: [
        {
          text: "id",
          value: "user_id",
          align: "center",
        },
        {
          text: "Name",
          value: "name",
          align: "center",
        },
        { text: "verifiziert", value: "verified", align: "center" },
        {
          text: "Email",
          value: "email",
          align: "center",
        },
        {
          text: "Berechtigungen",
          value: "level_of_user",
          align: "center",
        },
        {
          text: "Subscription",
          value: "get_emails_editor",
          align: "center",
        },
        {
          text: "Team",
          value: "team",
          align: "center",
        },
      ],
    },
    users: [],
    teams: [],
    selected_user: null,
    search: "",
    utilities: {
      dialog: false,
      new_team: "",
    },
  }),
  mounted() {
    this.getAllUsers();
  },
  methods: {
    getAllUsers() {
      AdminApi.getAllUsers().then((res) => {
        res.data.forEach((user) => {
          user.name = user.first_name + " " + user.last_name;
          this.users.push(user);
          if (user.team !== null && user.team !== "null" && user.team !== "") {
            if (!this.teams.includes(user.team)) this.teams.push(user.team);
          }
        });
      });
    },
    updateUserPermissions(user_id, level_of_user) {
      return AdminApi.updateUserPermissions(user_id, level_of_user);
    },
    updateUserVerification(user_id, verified) {
      return AdminApi.updateUserVerification(user_id, verified);
    },
    updateUserSubscription(user_id, verified) {
      return AdminApi.updateUserSubscription(user_id, verified);
    },
    updateUserTeam(user_id, team) {
      return AdminApi.updateUserTeam(user_id, team);
    },
  },
};
</script>

<style scoped></style>
