<template>
  <div class="login-view login-view--register">
    <h1>Registrieren</h1>
    <v-alert v-model="alert" type="error" dismissible>
      {{ errorMessages.toString() }}
    </v-alert>
    <v-form>
      <div class="login-container">
        <table>
          <tbody>
            <tr>
              <td class="pr-2">
                <v-text-field
                  ref="first_name"
                  v-model="first_name"
                  label="Vorname"
                  placeholder="Vorname"
                  required
                  :rules="[rules.required]"
                  outlined
                />
              </td>
              <td class="pl-2">
                <v-text-field
                  ref="last_name"
                  v-model="last_name"
                  label="Nachname"
                  placeholder="Nachname"
                  required
                  :rules="[rules.required]"
                  outlined
                />
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <v-text-field
                  ref="email"
                  v-model="email"
                  label="Email"
                  placeholder="Beispiel@email.de"
                  required
                  :rules="[rules.required, rules.validEmail]"
                  outlined
                />
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <PasswortInput
                  ref="password"
                  v-model="password"
                  label="Passwort"
                  hint="Mindestens 8 Zeichen lang"
                />
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <PasswortInput
                  ref="confirmPassword"
                  v-model="confirmPassword"
                  label="Passwort Bestätigen"
                  hint="Muss dem Passwort entsprechen"
                />
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <AcceptTerms
                  ref="accept_terms"
                  v-model="accept_terms"
                  label=""
                  hint="Muss akzeptiert werden."
                  required
                  :rules="[rules.required]"
                  outlined
                />
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <Button label_text="Registrieren" is-primary @click.native="register()" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-form>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import PasswortInput from "@/components/inputs/passwordInput";
import AcceptTerms from "@/components/inputs/acceptTerms";
import Button from "@/components/Button";
import authApi from "@/services/auth-api";

export default {
  name: "Register",
  components: { Button, PasswortInput, AcceptTerms, Footer },
  data() {
    return {
      first_name: null,
      last_name: null,
      birthdate: null,
      email: "",
      password: "",
      confirmPassword: "",
      accept_terms: false,
      alert: false,
      rules: {
        required: (value) => !!value || "This field is required",
        validEmail: (value) => {
          const emailPattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return emailPattern.test(value) || "Not a valid E-mail";
        },
      },
      errorMessages: [],
    };
  },
  computed: {
    form() {
      return {
        first_name: this.first_name,
        last_name: this.last_name,
        birthdate: "2000-01-01" /* TODO Remove entirely when database migrations work*/,
        email: this.email,
        password: this.password,
        accept_terms: this.accept_terms,
      };
    },
  },
  methods: {
    checkIfValid() {
      Object.keys(this.form).forEach((f) => {
        if (f !== "birthdate" && !this.$refs[f].validate(true)) {
          /* TODO Remove birthdate check when database migrations work*/
          this.errorMessages.push(" Bitte füllen sie das Feld " + this.$refs[f].label + " aus");
          return true;
        }
      });
      return false;
    },
    checkTermAcceptance() {
      return !this.accept_terms;
    },
    confirmPasswords() {
      if (!this.$refs.confirmPassword.validate(true)) {
        this.errorMessages.push(" Bitte füllen sie das Feld Passwort Bestätigen aus");
        return true;
      }
      if (
        this.password === this.confirmPassword &&
        this.confirmPassword.length >= 8 &&
        this.password.length >= 8
      ) {
        return false;
      } else {
        this.errorMessages.push(" Die Passwörter stimmen nicht überein");
        return true;
      }
    },
    register() {
      this.alert = false;
      this.errorMessages = [];
      let HasErrors = false;
      HasErrors = this.checkIfValid() || this.confirmPasswords() || this.checkTermAcceptance();
      if (!HasErrors) {
        authApi.checkEmailUnique({ email: this.form.email }).then((res) => {
          if (res.data.unique === false) {
            this.errorMessages.push(
              "Die E-mail Adresse wird bereits von einem anderen Account genutzt. Falls sie ihr Passwort vergessen haben schreiben sie Bitte eine E-mail an die Redaktion!"
            );
            this.alert = true;
          } else if (res.data.unique === true) {
            authApi
              .register(this.form)
              .then(async (success) => {
                if (success) {
                  this.$emit("ProfilePictureChange");
                  return true;
                }
              })
              .then((suc) => {
                if (suc) {
                  this.$router.push("/");
                }
              });
          }
        });
      } else {
        this.alert = true;
      }
    },
  },
};
</script>
