export function authHeader() {
  // return authorization header with jwt token
  if (getCookie("user")) {
    let user = JSON.parse(getCookie("user"));
    if (user && user.token) {
      return user.token;
    } else {
      return null;
    }
  }
  return null;
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}
