<template>
  <div class="text-left">
    <v-container>
      <v-row>
        <v-col cols="8">
          <v-btn color="primary" @click.stop="createDialog = true">Neu</v-btn>
          <v-dialog v-model="createDialog" max-width="400" persistent>
            <v-card>
              <v-card-title>Topic erstellen</v-card-title>
              <v-card-text>
                <v-text-field label="Name" v-model="editedName"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="cancelCreator">Abbrechen</v-btn>
                <v-btn text color="info" @click="createTopic">Speichern</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-col>
      </v-row>
    </v-container>
    <v-card v-if="editorIsOpen" elevation="24" tile>
      <v-card-title>
        <span class="text-h5">{{ editorTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4">
              <v-text-field v-model="editedName" required :label="table.headers[1].text" />
            </v-col>
            <v-col cols="8">
              <v-text-field v-model="editedSubheading" :label="table.headers[2].text" />
            </v-col>
            <v-col cols="12">
              <ckeditor :editor="editor" v-model="editedInformation" :config="editorConfig" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancelEditor"> Abbrechen </v-btn>
        <v-btn text color="info" @click="saveEditedItem"> Speichern </v-btn>
      </v-card-actions>
    </v-card>
    <v-data-table
      v-else
      :search="search"
      hide-default-footer
      :headers="table.headers"
      :items="topics"
    >
      <template v-slot:item="{ item }">
        <tr
          :class="{
            active: selected_topic?.topic_id === item.topic_id,
            'text--disabled': !item.approved,
          }"
          @click="selected_topic = item"
        >
          <td class="text-right">{{ item.topic_id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.subheading }}</td>
          <td>
            <v-simple-checkbox
              color="primary"
              v-model="item.approved"
              @input="
                updateTopicActive(item.topic_id, item.approved).then((res) => {
                  if (!res.data) {
                    item.approved = !item.approved;
                  }
                })
              "
            />
          </td>
          <td>
            <v-icon small @click.stop="openEditor(item)"> mdi-pencil </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AdminApi from "@/services/admin-api";
import Api from "@/services/api";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import config from "@/config.json";

export default {
  name: "TopicOptions",
  components: {},
  data: () => ({
    table: {
      headers: [
        {
          text: "id",
          value: "topic_id",
          align: "center",
        },
        {
          text: "Name",
          value: "name",
          align: "start",
        },
        {
          text: "Unterüberschrift",
          value: "subheading",
          align: "start",
        },
        {
          text: "Aktiviert",
          value: "approved",
          align: "start",
        },
        {
          text: "Aktionen",
          sortable: false,
        },
      ],
    },
    selected_topic: null,
    search: "",
    topics: [],
    editorIsOpen: false,
    editedName: null,
    editedSubheading: null,
    editedInformation: null,
    editor: ClassicEditor,
    editorConfig: config.editor,
    editorTitle: "",
    createDialog: false,
  }),
  mounted() {
    this.getAllTopics();
  },
  methods: {
    getAllTopics() {
      AdminApi.getAllTopics().then((res) => {
        this.topics = res.data
      });
    },
    updateTopicActive(topic_id, active) {
      return AdminApi.updateTopicActive(topic_id, active);
    },
    updateTopicInformation(topic_id, text) {
      return AdminApi.updateTopicInformation(topic_id, text);
    },
    async updateTopicSubheading(topic_id, text) {
      return AdminApi.updateTopicSubheading(topic_id, text);
    },
    async updateTopicName(topic_id, text) {
      return AdminApi.updateTopicName(topic_id, text);
    },
    openEditor(item) {
      this.selected_topic = item;
      this.editedName = item.name;
      this.editedSubheading = item.subheading;
      this.editedInformation = item.information;
      this.editorIsOpen = true;
    },
    cancelEditor() {
      this.editedName = null;
      this.editedSubheading = null;
      this.editedInformation = null;
      this.editorIsOpen = false;
    },
    cancelCreator() {
      this.editedName = null
      this.createDialog = false
    },
    async updateTopic() {
      if (this.editedSubheading !== this.selected_topic.subheading) {
        await this.updateTopicSubheading(this.selected_topic.topic_id, this.editedSubheading);
        this.selected_topic.subheading = this.editedSubheading;
      }

      if (this.editedName !== this.selected_topic.name) {
        await this.updateTopicName(this.selected_topic.topic_id, this.editedName);
        this.selected_topic.name = this.editedName;
      }

      if (this.editedInformation !== this.selected_topic.information) {
        await this.updateTopicInformation(this.selected_topic.topic_id, this.editedInformation);
        this.selected_topic.information = this.editedInformation;
      }
    },
    async createTopic() {
      if (!this.editedName) {
        return alert("Name darf nicht leer sein!");
      }

      const existingTopic = this.topics.filter((topic) => {
        return topic.name === this.editedName;
      });

      if (existingTopic.length) {
        return alert("Name ist bereits vergeben!");
      }

      await Api.postNewTopic(this.editedName);
      this.createDialog = false
      await this.getAllTopics();   
    },
    async saveEditedItem() {
      await this.updateTopic();
      this.editorIsOpen = false;
    },
  },
};
</script>

<style scoped></style>
