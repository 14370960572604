<template>
  <v-container class="feed-comp">
    <div v-for="postcard in postcards" :key="postcard.id" class="feed__postcard">
      <div class="postcard-wrapper" :class="{ unApproved: !postcard.approved && !postcard.show }">
        <div v-if="!postcard.approved" class="unApproved_note" :class="{ 'd-none': postcard.show }">
          <span v-if="postcard.hidden">Diese Postkarte wurde von der Redaktion abgelehnt!</span>
          <span v-if="!postcard.hidden">Diese Postkarte ist noch nicht freigegeben!</span>
          <br />
          <span v-if="loggedInUser != null && loggedInUser.level_of_user !== 'normal'">
            Als "{{ loggedInUser.level_of_user }}" kannst du die Postkarte
            <a @click="showPostcard(postcard)">jetzt prüfen</a>!
          </span>
        </div>
        <PostCard :postcard_info="postcard" :loggedInUser="loggedInUser" class="postcard" />
        <PostcardBar
          v-if="postcard.approved"
          :id="postcard.id"
          ref="postcardBar"
          v-model="postcard.isSaved"
          class="button_row"
          :bookmarked="postcard.isSaved"
          :sender="postcard.sender"
          :topic="postcard.subject"
          :postcard="postcard"
          @showModal="openBookmarkModal()"
        />

        <div id="bookmarkModal" class="modal" @click="closeBookmarkModal($event)">
          <div class="modal-content">
            <span class="close" @click="closeBookmarkModal($event)"><v-icon>$x</v-icon></span>
            <p class="bookmark-modal-heading heading-three font-dark-grey">Postkarte speichern</p>
            <p class="bookmark-modal-text font-dark-grey font-medium">
              Du kannst Postkarten speichern, wenn du ein Konto angelegt hast. Möchtest du jetzt ein
              eigenes Profil erstellen?
            </p>
            <Button
              label_text="Profil erstellen"
              :is-primary="true"
              @click.native="
                $event.stopPropagation();
                $router.push('/register');
              "
            />
          </div>
        </div>
      </div>
      <!-- TODO here would be where the children have to be displayed
      which would basically be another for each but with the children of postcard
      so we should make this whole foreach block a reusable component with a prop "isChild"
       keywords for search: parent answer {{ postcard.children.length }}
      -->
    </div>
    <div class="feed__end">
      <span v-if="isEndReached">
        Du hast alle Postkarten zu diesem Thema gelesen. Wenn du magst, kannst du selbst eine
        schreiben.
      </span>
      <span v-if="!isEndReached">
        Wenn du diesen Text liest, hat etwas mit dem Nachladen weiterer Postkarten nicht
        funktioniert. Versuche, ein Stück hochzuscrollen oder die Seite neuzuladen, um die
        restlichen Postkarten lesen zu können.
      </span>
    </div>
  </v-container>
</template>
<script>
import PostCard from "@/components/PostCard";
import PostcardBar from "./PostcardBar";
import Button from "./Button";
import { getLoggedInUser } from "@/services/loggedIn";

export default {
  name: "Feed",
  components: { PostcardBar, PostCard, Button },
  props: {
    postcards: { type: Array },
    isEndReached: { type: Boolean },
  },
  computed: {
    loggedInUser() {
      return JSON.parse(getLoggedInUser());
    },
  },
  methods: {
    showPostcard(postcard) {
      if (postcard.approved) {
        postcard.show = true;
      } else if (this.loggedInUser.level_of_user !== "normal") {
        postcard.show = true;
        postcard.readOnly = false;
      }
    },

    hidePostcard(postcard) {
      postcard.show = false;
      postcard.readOnly = true;
    },

    openBookmarkModal() {
      var modal = document.getElementById("bookmarkModal");
      modal.style.display = "block";
    },
    closeBookmarkModal(event) {
      event.stopPropagation();
      var modal = document.getElementById("bookmarkModal");
      modal.style.display = "none";
    },
    downloadPostcard(event, index) {
      this.$refs.postcardBar[index].downloadPostcard(event);
    },
    modalPostcardSaved(event, index) {
      this.$refs.postcardBar[index].savePostcard(event);
    },
  },
};
</script>
