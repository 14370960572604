<template>
  <Progressbar :topicId="topicId" />
</template>

<script>
import Progressbar from "@/components/Progressbar";

export default {
  name: "CreatePostcard",
  components: { Progressbar },
  data: () => ({
    topicId: null,
  }),
  mounted() {
    this.topicId = this.$route?.params?.id || null;
  },
};
</script>
