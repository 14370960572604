<template>
  <v-text-field
    ref="password"
    v-model="password"
    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
    :rules="[rules.required, rules.min]"
    :type="show ? 'text' : 'password'"
    :label="label"
    :hint="hint"
    counter
    outlined
    @click:append="show = !show"
  />
</template>

<script>
export default {
  name: "PasswordInput",
  props: {
    label: String,
    hint: String,
  },
  data() {
    return {
      show: false,
      password: "",
      rules: {
        required: (value) => !!value || "Es wird ein Passwort benötigt",
        min: (v) => (!!v && v.length >= 8) || "Das Passwort muss mindestens 8 Zeichen lang sein",
      },
    };
  },
  watch: {
    password(val) {
      this.$emit("input", val);
    },
  },
  methods: {
    validate(bool) {
      return this.$refs.password.validate(bool);
    },
    getValue() {
      return this.password;
    },
  },
};
</script>
