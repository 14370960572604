<template>
  <div class="mobile-navigation-comp">
    <v-app-bar-nav-icon class="d-md-none" @click.stop="drawer = !drawer" />
    <v-navigation-drawer v-model="drawer" absolute bottom temporary class="mobile-navigation">
      <v-list nav dense>
        <v-list-item
          v-for="item in menu"
          :key="item.to"
          :to="item.to"
          :class="{ active: name === item.active }"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>

      <AccountNavigation />
    </v-navigation-drawer>
  </div>
</template>
<script>
import AccountNavigation from "./AccountNavigation";

export default {
  name: "MobileNavigation",
  components: { AccountNavigation },
  props: {
    menu: {
      type: Array,
    },
    name: {
      type: String,
    },
  },
  data: () => ({
    drawer: false,
  }),
};
</script>
