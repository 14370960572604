<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.92337 0.173376C4.97862 0.118274 5.04418 0.0745998 5.11631 0.0448509C5.18844 0.015102 5.26573 -0.000138403 5.34375 9.47023e-07H13.6562C13.8137 9.47023e-07 13.9647 0.0625566 14.0761 0.173906C14.1874 0.285256 14.25 0.436279 14.25 0.593751C14.25 1.40125 13.8439 1.98788 13.4829 2.35006C13.3333 2.4985 13.186 2.61606 13.0625 2.70394V7.96575L13.1551 8.02275C13.3962 8.17356 13.7204 8.39563 14.0469 8.68181C14.6775 9.23281 15.4375 10.1258 15.4375 11.2813C15.4375 11.4387 15.3749 11.5897 15.2636 11.7011C15.1522 11.8124 15.0012 11.875 14.8438 11.875H10.0938V17.2188C10.0938 17.5465 9.82775 19 9.5 19C9.17225 19 8.90625 17.5465 8.90625 17.2188V11.875H4.15625C3.99878 11.875 3.84776 11.8124 3.73641 11.7011C3.62506 11.5897 3.5625 11.4387 3.5625 11.2813C3.5625 10.1258 4.3225 9.23281 4.95188 8.68181C5.25908 8.41506 5.58886 8.17547 5.9375 7.96575V2.70394C5.78775 2.59791 5.64713 2.47954 5.51712 2.35006C5.15612 1.98788 4.75 1.40006 4.75 0.593751C4.74986 0.515726 4.7651 0.438439 4.79485 0.366308C4.8246 0.294178 4.86827 0.228617 4.92337 0.173376V0.173376ZM6.79962 1.84538L6.79725 1.84419L6.79962 1.84538ZM6.79725 1.84419L6.79962 1.84538C6.8975 1.89497 6.97972 1.97072 7.03716 2.06421C7.09459 2.1577 7.125 2.26528 7.125 2.375V8.3125C7.125 8.42268 7.09434 8.53068 7.03646 8.62442C6.97857 8.71817 6.89575 8.79396 6.79725 8.84331H6.79488L6.78063 8.85163L6.7165 8.88725C6.36639 9.08255 6.03731 9.31335 5.73444 9.576C5.35681 9.90613 5.03975 10.2838 4.8735 10.6875H14.1265C13.9602 10.2838 13.6432 9.90613 13.2656 9.576C12.9443 9.29714 12.5935 9.05423 12.2194 8.85163L12.2051 8.8445H12.2028C12.1041 8.79505 12.0211 8.71908 11.9632 8.6251C11.9053 8.53113 11.8748 8.42288 11.875 8.3125V2.375C11.8746 2.26018 11.9074 2.14769 11.9696 2.05116C12.0318 1.95463 12.1206 1.8782 12.2253 1.83113C12.3772 1.7417 12.5172 1.63359 12.6421 1.50931C12.7371 1.41431 12.8262 1.30744 12.8962 1.1875H6.10375C6.175 1.30625 6.26169 1.41431 6.35788 1.50931C6.48905 1.63968 6.63677 1.75227 6.79725 1.84419Z"
      fill="#5F5F5F"
    />
  </svg>
</template>

<script>
export default {
  name: "Pin",
};
</script>
